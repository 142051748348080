import SearchModal from 'components/Common/SearchModal';
import Image from 'next/image';
import { useState } from 'react';

import { staticMediaStoreBaseURL } from 'config/common';

export default function MobileHomeBanner() {
  const [showSearchModal, setShowSearchModal] = useState(false);

  const handleSearchClick = (e) => {
    e.preventDefault();
    setShowSearchModal(true);
  };

  const SerarchBar = () => (
    <div
      className='transform translate-y-10 mt-22'
      onClick={handleSearchClick}
    >
      <div
        className='relative rounded-7.5 bg-white max-w-68 mx-auto w-full h-10 flex
  items-center px-5 xxxs:px-6 py-3 cursor-pointer shadow-search transition-shadow
  ease-in-out focus:bg-white focus-within:bg-white hover:bg-white'
      >
        <div className='relative h-4 w-4'>
          <Image
            src={`${staticMediaStoreBaseURL}/icons/search_new.svg`}
            layout='fill'
            height={16}
            width={16}
            className='cursor-pointer'
            alt='Search'
          />
        </div>
        <p
          className='text-nero font-Inter font-semibold text-xs basic:text-sm
    leading-4 ps-3 basic:ps-4 cursor-pointer'
        >
          {' '}
          What are you searching for?{' '}
        </p>
      </div>
    </div>
  );

  return (
    <section id='banner-section'>
      {showSearchModal && (
        <SearchModal setShowSearchModal={setShowSearchModal} />
      )}
      <div className='bg-banner p-5 h-full w-full bg-cover bg-center'>
        <SerarchBar />
      </div>
    </section>
  );
}
