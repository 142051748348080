import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import SearchSuggestion from 'components/Common/SearchSuggestion';
import { staticMediaStoreBaseURL } from 'config/common';
import { getSuggestions } from 'services/search.service';

export default function CustomSearchBox({
  searchedValue = '',
  onScrolling,
  homeScreen
}) {
  const Router = useRouter();
  const [searchValue, setSearchValue] = useState(searchedValue);
  const [suggestions, setSuggestions] = useState([]);
  const handleSearch = async (e) => {
    setSearchValue(e.target.value);
    const response = await getSuggestions(e.target.value);
    setSuggestions(
      response.status && response.entity.count ? response.entity.hits : []
    );
  };
  useEffect(() => {
    setSearchValue(searchedValue);
  }, [searchedValue]);

  const selectingSuggestion = (suggestion) => {
    setSearchValue(suggestion);
    setSuggestions([]);
    Router.push(`/search-result/${suggestion}`);
  };

  return (
    <div
      className={`py-6 z-111 center-text h-5 bg-white flex items-center md:rounded-full ${
        homeScreen && onScrolling && 'md:border md:border-platinum'
      } ${
        !homeScreen && 'md:border md:border-platinum'
      } w-full md:w-88 lg-l:w-2/5 xl:w-110 md:h-16 bottom-20`}
    >
      <div className='relative h-5 w-4 ml-4 search-icon-size'>
        <Image
          src={`${staticMediaStoreBaseURL}/icons/search_new.svg`}
          layout='fill'
          className='cursor-pointer'
          alt='Search'
        />
      </div>
      <input
        className='font-Inter font-semibold text-xs basic:text-lg basic:ps-2 w-full h-8 outline-none md:rounded-full pr-5 placeholder-black'
        id='searchDesktop'
        type='text'
        placeholder='What are you searching for?'
        autoComplete='off'
        value={searchValue}
        onChange={handleSearch}
        onFocus={(e) => {
          e.target.value && handleSearch(e);
        }}
        onKeyDown={(e) => {
          if (e.which === 13 && e.target.value.length) {
            selectingSuggestion(searchValue);
          }
        }}
      />
      {Boolean(suggestions.length) && (
        <>
          {' '}
          <div
            onClick={(e) => {
              e.stopPropagation();
              if (e.target.id === 'modal') {
                return setSuggestions([]);
              }
            }}
            id='modal'
            className='fixed z-20 w-full h-full inset-0 flex md:items-center items-end'
          ></div>
          <SearchSuggestion
            {...{
              suggestions,
              selectingSuggestion,
              searchValue,
              className: 'top-16'
            }}
          />
        </>
      )}
    </div>
  );
}
